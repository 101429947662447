@media only screen and (max-width: 767px) {
    .login-sec {
      overflow: visible;
   }
    .car-img {
        position: absolute;
        left: 0;
        max-width: 90%;
        top: 22px;
        left: 27px;
        height: 100%;
        object-fit: contain;
    }
    .login-frm {
       margin: 7% 2% 7% 2%;
     }
}

@media screen and (max-width: 992px) {
  .grid-container {
    grid-template-columns: 1fr;
    grid-template-rows: 0.2fr 3fr;
    grid-template-areas:
      'header'
      'main';
  }

  #sidebar {
    display: none;
  }

  .menu-icon {
    display: inline;
  }

  .sidebar-title > span {
    display: inline;
  }
}
/* Small <= 768px */
  
@media screen and (max-width: 768px) {
  .login-form-sec {
   min-width: 90%;
}
  .header-right {
    gap: 14px;
    margin-left: 25px;
}
  .user-details img {
    width: 40px;
   
}
  .header-left h3 {
    font-size: 20px;
    line-height: 40px;

}
.header {
  padding: 0 10px 0 10px;
  gap: 5px;
  justify-content: flex-start;
}
  .main-cards {
    grid-template-columns: 1fr;
    gap: 10px;
    margin-bottom: 0;
  }

  .charts {
    grid-template-columns: 1fr;
    margin-top: 30px;
  }
}

/* Extra Small <= 576px */

@media screen and (max-width: 576px) {
  .hedaer-left {
    display: none;
  }
}

@media only screen and (max-width: 991px) {
  .grid-container {
  position: relative;
}
  .user-details {
    display: flex;
    align-items: center;
}
}


@media only screen and (min-width: 768px) and (max-width: 991px) {
  .login-form-sec {
    min-width: 70%;
 }
}

@media only screen and (min-width: 992x) and (max-width: 1360px) {
  .login-form-sec {
    min-width: 60%;
 }
} 
@media only screen and (max-width: 1211px) {

}

@media only screen and (max-width: 1051px) {
    
}

@media only screen and (max-width: 992px) {
   
}

@media only screen and (max-width: 850px) {

 

}

@media only screen and (max-width: 660px) {
    

}