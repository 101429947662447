@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap");

* {
  margin: 0px;
  padding: 0px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html,
body {
  scroll-behavior: smooth;
  overflow-x: hidden;
  padding-right: 0 !important;
}

body {
  font-family: "Poppins", sans-serif;
}

/* =============================
          Default Css
============================== */
a {
  text-decoration: none;
  outline: 0 !important;
}

a:hover {
  text-decoration: none;
}

:focus {
  outline: 0 !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

img {
  max-width: 100%;
  height: auto;
}

h1,
h2,
h3,
h4,
h5,
h6,
ul,
p {
  margin: 0px;
  padding: 0px;
}

ul {
  list-style-type: none;
}

.login-sec {
  position: relative;
  /* background-image: url('../images/body-bg.png'); */
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  overflow: hidden;
  font-family: "Poppins", sans-serif;
}
.right-side {
  position: relative;
  height: 100vh;
}
.bg-img {
  height: 100vh;
  width: -webkit-fill-available;
  display: flex;
  flex-basis: fit-content;
  background-size: cover;
 
}
.login-frm {
  background: #FFFFFF;
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.1);
  border-radius: 46px;
  margin: 0px -30% 0px 20%;
  position: relative;
  z-index: 9;
  padding: 40px 40px 40px 40px;
  display: grid;
  text-align: center;
  gap: 35px;
  text-align: -webkit-center;
}
.login-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.car-img {
  position: absolute;
  min-width: 99%;
  top: 40px;
  left: 20px;
  height: 100%;
  object-fit: contain;
}
.choose-type  input[type="radio"] {
  accent-color: #000090;
}
.login-frm label {
  font-family: Poppins;
  font-size: 15px;
  font-weight: 400;
  line-height: 12px;
  text-align: left;
}
.guest-login {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 25px;
}
.login-form-sec input[type="text"] {
  box-sizing: border-box;
  background: #FFFFFF;
  border: 1px solid #AEAEAE;
  border-radius: 100px;
  padding: 15px 40px 15px 75px;
  font-size: 13px;
  width: 100%;
  min-height: 60px;
}
.login-form-sec input[type="number"] {
  box-sizing: border-box;
  background: #FFFFFF;
  border: 1px solid #AEAEAE;
  border-radius: 100px;
  padding: 15px 40px 15px 75px;
  font-size: 13px;
  width: 100%;
  min-height: 60px;
}
.login-form-sec input[type="password"] {
  box-sizing: border-box;
  background: #FFFFFF;
  border: 1px solid #AEAEAE;
  border-radius: 100px;
  padding: 15px 40px 15px 75px;
  font-size: 13px;
  width: 100%;
  min-height: 60px;
}
.login-form-sec input[type="email"] {
  box-sizing: border-box;
  background: #FFFFFF;
  border: 1px solid #AEAEAE;
  border-radius: 100px;
  padding: 15px 40px 15px 75px;
  font-size: 13px;
  width: 100%;
}
.form-group button {
  background: #000090;
  color: white;
  border: 0;
  padding: 15px 50px;
  border-radius: 100px;
  font-family: Poppins;
  font-size: 15px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.2em;
  text-align: center;
}
.form-group button:hover {
  background: #000090;
  color: white;
}
.create-btn {
  width: max-content;
  font-family: Poppins;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.05em;
  text-align: center;
  color: #535252;
  border-bottom: 1px solid black;
  margin-top: 15px;
  display: block;
}
.login-top img {
  width: 140px;
}
.form-grp {
  position: relative;
}
.form-group{
  position: relative;
}
.form-group span {
  position: absolute;
  left: 25px;
  width: 18px;
  top: 21%;
}

.form-grp select {
    position: absolute;
    border: 0;
    background-color: transparent;
    left: 40px;
    top: 13px;
    color: #bbbbbb;
}

/* Hide the default radio button */
input[type="radio"] {
  display: none;
}

/* Create a custom radio button */
input[type="radio"] + label {
  position: relative;
  padding-left: 25px;
  cursor: pointer;
}

input[type="radio"] + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #AEAEAE;
  border: 3px solid #fff;
 outline: 1px solid #AEAEAE;
}

input[type="radio"]:checked + label:before {
  background-color: #000090;
  border-color: #ffffff;
  outline: 1px solid #000090;
}

input[type="radio"]:focus + label:before {
  box-shadow: 0 0 0 2px #66bb6a;
}
.rdo {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 30px;
}
.css-b62m3t-container {
  width: max-content;
  border: 0;
}
.css-1xc3v61-indicatorContainer {
 padding: 0 !important;
}
.css-13cymwt-control{
  border: 0 !important;
}
.css-b62m3t-container {
  width: max-content;
  border: 0 !important;
  position: absolute !important;
  top: 6px;
  background-color: transparent;
  left: 15px;
}
.css-hlgwow {
  padding: 0 !important;
  
}
.css-tj5bde-Svg {
 width: 14px;
  left: -5px;
  position: relative;
}
/* .form-group button:hover::before{
   content: "\f178";
   position: absolute;
   width: 20px;
   height: 20px;

} */

/* 
booking */

.booking-main {
 background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #000090;
}
.booking-inner {
  width: 90%;
  margin: 0 auto;
  padding: 30px 0;
}
.nav-top {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
.nav-link a {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #FFFFFF;
}
.nav-link {
  display: flex;
  align-items: center;
  gap: 80px;
}
.login-form-sec input[type="date"] {
  box-sizing: border-box;
  background: #FFFFFF;
  border: 1px solid #AEAEAE;
  border-radius: 100px;
  padding: 15px 40px 15px 75px;
  font-size: 13px;
  width: 100%;
  color: #535252;

}

.login-form-sec select.form-select{
  box-sizing: border-box;
  background: #FFFFFF;
  border: 1px solid #AEAEAE;
  border-radius: 100px;
  padding: 15px 40px 15px 60px;
  font-size: 13px;
  width: 100%;
}
.inner-left.login-frm {
  margin-left: 0;
}
.drpdwn {
  position: absolute;
  top: 11px;
  right: 18px;
  cursor: pointer;
}

.guest-login  input[type="radio"] {
  accent-color: #000090;
}
.bk-log {
  margin: 20px 0;
}
.inner-left {
  display: block;
  position: relative;
  max-width: 88%;
  float: right;
}
.booking-inner-content {
  background: #FFFFFF;
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.1);
  border-radius: 46px;
  margin: -45px 0px 0px 0px;
  position: relative;
  z-index: 9;
  padding: 45px 50px 10px 50px;
  display: grid;
  text-align: center;
  gap: 35px;
}
.inner-right > h2 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 52px;
  color: #000000;
  text-align: left;
  padding-bottom: 25px;
}
.nav-logo {
  background: #fff;
  padding: 12px 25px 60px 25px;
  position: relative;
  border-radius: 46px 45px 0px 0px;
}
.nav-logo img {
  width: 100px;
}
.rotate-dots {
  position: absolute;
  inset: 0;
  z-index: -1;
  -webkit-animation: rotate 35s normal linear infinite;
  animation: rotate 35s normal linear infinite;
}
.car-imgg {
  max-width: 80%;
  margin: 100px 0px 0px 70px;
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.form-group button{
 }
.form-group button:hover {
  background-position: left bottom;
  transition: all .5s ease-out;
  background: linear-gradient(to right, #62d43f 50%, #3cff00);
  background-size: 200% 100%;
  background-position: right bottom;
}

.form-group select {
  min-height: 60px;
  box-sizing: border-box;
  background: #FFFFFF;
  border: 1px solid #AEAEAE;
  border-radius: 100px;
  padding: 15px 40px 15px 75px;
  font-size: 13px;
  width: 100%;
  color: #535252;
}
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
}
.form-group span.input-icon {
  position: absolute;
  left: 40px;
  width: 20px;
  top: 16px;
}
.confim-heading{
   font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 52px;
    color: #000000;
    text-align: left;
    padding-bottom: 30px;
}
.confim-heading img {
  width: 33px;
}
.confirm-booking {
  box-shadow: 0px 0px 50px 0px #FFC53026;
  border-radius: 30px;
  display: grid;
  text-align: left;
  padding: 25px 22px;
  margin-bottom: 70px;
}
.booking-id p {
  font-family: Poppins;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: #808080;
}
.download-booking h2 {
  font-family: Poppins;
  font-size: 30px;
  font-weight: 600;
  line-height: 30px;
  text-align: left;
  padding: 0;
}
.download-booking  {
   display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0px 20px 0px;
}
.confirm-booking p {
  font-family: Poppins;
  font-size: 13px;
  font-weight: 500;
  line-height: 15px;
  text-align: left;
  color: #757474;
}
.confirm-booking h4 {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
  color: #407BFF;
}
.drop-details {
  position: relative;
  margin-bottom: 14px;
}
.pick-details{
  position: relative;
  margin-bottom: 14px;
}
.vehicle-details {
   display: flex;
    align-items: center;
    gap: 35px;
    padding-bottom: 20px;
    border-bottom: 1px solid #AAAAAA;
}
h4.time-count {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;
  line-height: 22px;
  text-align: left;
  color: #000000;
  margin: 20px 0px;
}
h4.amount-count{
  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;
  line-height: 22px;
  text-align: left;
  color: #000000;
  margin: 0px;
}
.car-rotate {
  max-width: 100%;
  margin: 0px;
  -webkit-animation: rotate-car 5s linear;
  animation: rotate-car 5s linear;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  overflow: hidden;
}

@keyframes rotate-car {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-73deg);
  }
}
.confirm-car {
  float: right;
  padding: 0px 50px 0px 0px;
  display: block;
  width: 100%;
  text-align: right;
  position: relative;
  overflow: hidden;
}
.grn-bg {
  position: absolute;
  left: -60px;
  top: 0;
  bottom: 0;
  background-size: cover;
  object-fit: contain;
}
.show-error{
  margin: -20px 0px 20px 0px;
}
.css-13cymwt-control {
 border-color: hsl(0, 0%, 80%);
 
}
.css-1ko6sys-MuiStack-root-MuiMultiInputDateTimeRangeField-root {
  flex-direction: column !important;
  overflow: hidden;
}
.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  height: 60px !important;
}
.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  
  border-color: transparent !important;
}
.css-1ko6sys-MuiStack-root-MuiMultiInputDateTimeRangeField-root>:not(style)~:not(style) {
  margin-left: 0px !important;
}
.css-yffnkk-MuiTypography-root-MuiMultiInputDateTimeRangeField-separator{
  color: white;
}
.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  left: 60px !important;
  top: 0px !important;
  font-size: 14px !important;
  color: var(--bs-body-color);
}
.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  top: 20px;
  color: #1976d2;
  left: 60px;
}
.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root {
 
  left: 60px;
  top: 15px;
  
}
.checkin-calender {
  z-index: 9;
  left: 22px !important;
  top: 21% !important;
  position: absolute;
}

.css-19bb58m input {
  height: auto !important;
  min-height: auto !important;
  margin-top: 15px !important;
}
.css-md26zr-MuiInputBase-root-MuiOutlinedInput-root  input[type="text"] {
padding: 30px 40px 15px 75px;

}
.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root{
  left: 60px !important;
  top: 15px !important;
}
.booking-summry {
  box-shadow: 0px 0px 50px 0px #FFC53026;
  border-radius: 30px;
  display: grid;
  text-align: left;
  padding: 25px 22px;
  margin-bottom: 70px;
}
.summry-box {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.summry-box h4 {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
  color: #407BFF;
  min-width: 50%;
}
.submit-btn {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 20px 0px 20px 0px;
  border-top: 1px solid thistle;
}
.accept-btn{
   background: #63d53f;
    color: white;
    border: 0;
    padding: 15px 50px;
    border-radius: 100px;
    font-family: Poppins;
    font-size: 15px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.2em;
    text-align: center;
}
.cancel-btn{
  background: #f44336;
   color: white;
   border: 0;
   padding: 15px 50px;
   border-radius: 100px;
   font-family: Poppins;
   font-size: 15px;
   font-weight: 500;
   line-height: 22px;
   letter-spacing: 0.2em;
   text-align: center;
}
.summry-box p {
  font-weight: bold;
  color: #3e4e72;
}
.summry-box  span {
  font-size: 15px;
  font-weight: 500;
}
.timer-wrapper {
  position: relative;
  margin-bottom: 40px;
}
.timer-text span:last-child{
  display: none;
}
.timer-text span {
  font-size: 50px;
}
.download-invoice {
  display: flex;
  align-items: center;
  gap: 10px;
}
.download-invoice img {
  width: 30px;
  background-size: contain;
  height: fit-content;
}
.error  {
  font-size: 12px;
  text-align: left;
  padding: 2px 0px 0px 25px;
  color: red;
}
.form-group button.MuiIconButton-edgeEnd  {
  position: absolute;
  inset: 0;
  opacity: 0;
}
.css-z3c6am-MuiFormControl-root-MuiTextField-root {
  width: -webkit-fill-available;
  margin-bottom: 20px;
}
.cus-cal .MuiInputAdornment-root:first-child{
  margin-bottom: 25px !important;
}

/* booking-css */
.booking-list {
  display: inline-block;
  margin: 0px 20px;
  background-color: white;
  border-radius: 20px;
  padding: 15px;
  grid-area: main;
}

.booking-filter-left h3 {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  text-align: left;
}

.booking-filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.user-list {
  display: inline-block;
  margin: 0px 20px;
  background-color: white;
  border-radius: 20px;
  padding: 20px 30px;
  grid-area: main;
}

.top-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin-bottom: 30px;
}

.right-open-modal {
  width: 400px;
  padding: 20px;
  background-color: white;
  height: 100%;
  right: 0;
  position: absolute;
  overflow-y: scroll;
  scrollbar-width: thin;
}

.top-section h3 {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  text-align: left;
}

.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
  width: -webkit-fill-available;
}

.right-open-modal label {
  width: -webkit-fill-available;

}

.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  font-size: 14px;
}

.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
  margin-bottom: 15px !important;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 15px 14px !important;
  font-size: 14px !important;
}

.css-ahj2mt-MuiTypography-root {
  font-size: 14px !important;
}

.right-open-modal h6 {
  margin-bottom: 10px;
}

.MuiTableCell-head {
  background-color: #DFE6FF !important;
  color: #000000 !important;

}

.css-1nrlq1o-MuiFormControl-root {
  width: -webkit-fill-available;
}

.right-open-modal h4 {
  margin-top: 25px;
}

select {
  word-wrap: normal;
  height: 50px;
  width: -webkit-fill-available;
  margin-bottom: 15px;
  border-color: #d2d2d2;
  border-radius: 5px;
  color: #666666;
  font-size: 14px;
  padding-left: 10px;
}

.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  width: -webkit-fill-available;
  margin-bottom: 15px;
}

.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  margin-bottom: 20px;

}

.css-1hl290c {
  padding: 0 !important;
}

.setting-sec {
  display: inline-block;
  margin: 0px 20px;
  background-color: white;
  border-radius: 20px;
  padding: 20px 30px;
  grid-area: main;
}

.feature-box {
  background: #D7E4DD4F;
  border-radius: 15px;
  position: relative;
  display: grid;
  padding: 20px;
  cursor: pointer;
}

.feature-box img {
  width: 70px;
  margin-bottom: 16px;
}

.feature-box h6 {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  text-align: left;
}

.feature-box p {
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
}

.css-45defc-MuiDataGrid-root {
  --unstable_DataGrid-radius: 10px;
  border-color: rgba(224, 224, 224, 1);
}

.css-1hl290c {
  height: 500px !important;

}

.top-section .css-i4bv87-MuiSvgIcon-root {
  background-color: #13D3A9;
  border-radius: 50%;
  margin-right: 8px;
  color: #ffffff !important;
}

.action-btn {
  background-color: transparent !important;
  font-family: Poppins !important;
  font-size: 13px !important;
  font-weight: 600 !important;
  line-height: 26px !important;
  text-align: left;
  color: #13d3a9 !important;
}

.inner-container {
  display: inline-block;
  margin: 0px 20px;
  background-color: white;
  border-radius: 20px;
  padding: 20px 30px;
  grid-area: main;
}

.css-1vimobo {
  max-width: 100% !important;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #ffffff !important;
  background-color: #407BFF;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  font-family: Poppins !important;
  font-size: 16px !important;
  font-weight: 500 !important;
}

.rate-chart-sec {
  position: relative;
  display: block;
}

.chart-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
}

.chart-list ul {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 10px;
  position: relative;
  list-style: none;
}

.chart-list ul li a {
  border: 1px solid #808080;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  line-height: 30px;
  text-align: center;
  color: #808080;
  padding: 10px 14px;
  border-radius: 6px;
  margin-bottom: 10px;
}

.chart-list ul li {
  margin-bottom: 10px;
}

.css-13xfq8m-MuiTabPanel-root {
  padding: 15px 10px;
}

.chart-details h6 {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 25px;
  text-align: left;
}

.dc-footer,
.dc-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
}

.dc-head {
  font-size: 20px;
  font-weight: 700;
  color: #303030;
}

.dch-right {
  display: flex;
  align-items: center;
}


/* Main  */
.main-container {
  grid-area: main;
  overflow-y: auto;
  padding: 20px 20px;
  color: rgba(255, 255, 255, 0.95);
  scrollbar-width: none;
}

.main-title {
  display: flex;
  justify-content: space-between;
}

.main-cards {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  box-shadow: 0px 0px 30px 0px #0000000F;
  border: 1px solid #F6F6F6;
  background: #FFFFFF;
  border-radius: 15px;
}

.card-inner {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 20px 10px;
  border-radius: 5px;
  align-items: flex-end;
  position: relative;
}

.card h1 {
  color: white;
}

.card-inner>.card_icon {
  font-size: 25px;
}


.custom-table h6 {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  text-align: left;
  min-width: max-content;
}

.action-btn {
  display: flex !important;
  align-items: center !important;
  gap: 0px;
  min-width: max-content !important;
  min-height: 42px;
  border-radius: 8px !important;
  box-shadow: 0px 0px 20px 0px #00000014 !important;
  padding: 5px 10px !important;
}

.action-btn .css-i4bv87-MuiSvgIcon-root {
  background-color: #13D3A9;
  border-radius: 50%;
  margin-right: 8px;
  color: #ffffff !important;
  box-shadow: 0px 0px 20px 0px #00000014;
}

.custom-table .css-hyum1k-MuiToolbar-root {
  padding: 0;
}

.custom-table .css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
  margin-bottom: 0px !important;
  margin-right: 10px !important;
  max-width: 150px;
}

.custom-table .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  margin-bottom: 0px;
  max-width: 150px;
}

.custom-table .css-1pysi21-MuiFormLabel-root-MuiInputLabel-root {
  color: #808080;
  font-family: 'Poppins';
  font-size: 12px !important;
  line-height: 20px !important;

}

.custom-table .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
  color: #808080;
  font-family: 'Poppins' !important;
  font-weight: 400;
  font-size: 12px !important;
  line-height: 20px !important;
  min-height: 41px;
  max-width: 145px;
}

.custom-table .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  color: #808080 !important;
  font-family: 'Poppins' !important;
  font-weight: 400;
  font-size: 12px !important;
  line-height: 10px !important;
}

.custom-table .css-i4bv87-MuiSvgIcon-root {
  width: 20px;
}

.custom-table .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 9px 14px !important;
}

.custom-table .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  margin-bottom: 0px;
  margin-right: 10px;
  max-width: 150px;
}

.custom-table .css-i4bv87-MuiSvgIcon-root {
  width: 20px !important;
  padding: 3px !important;
  font-size: 20px !important;
  margin-right: 5px !important;
}

.custom-table .css-1nrlq1o-MuiFormControl-root {
  margin-right: 10px !important;
}

.custom-table .css-q8hpuo-MuiFormControl-root {
  max-width: 150px;

}

.custom-table .css-1nrlq1o-MuiFormControl-root {
  max-width: 150px;
}



.card__title {
  height: 12px;
  margin-bottom: 7px;
  border-radius: 40px;
  width: 100%;
}
.custom-table {
  position: relative;
}
.custom-table table td, .custom-table table th {
  font-size: 13px;
  padding: 15px 10px;
}
.table>thead {
  vertical-align: top;
  background-color: #dfe6ff;
}
table, th, td {
  border: 1px solid rgb(33 37 48 / 18%);
  border-collapse: collapse;
}
.flx-box{
  display: flex;
  align-items: center;
}

.select-time .css-z3c6am-MuiFormControl-root-MuiTextField-root{
  width: -webkit-fill-available;
  margin-top: 40px;
}

.grid-container {
  display: grid;
  grid-template-columns: 0px 1fr 1fr 1fr;
  grid-template-rows: 0.2fr 3fr;
  grid-template-areas: 'sidebar header header header'
    'sidebar main main main';
  min-height: 100vh;
  background-color: #f6f8ff;
}



/* ==========reciept=============== */
.top-table
table,
.top-table div,
.top-table h1,
.top-table h2,
.top-table h3,
.top-table h4,
.top-table h5,
.top-table h6,
.top-table p {
    font-family: "Poppins", sans-serif;
    margin: 0;
}


.top-table p {
    padding: 4px 0;
    color: hsla(0, 0%, 42%, 1);
    font-size: 12px;
    white-space: normal;
    line-height: 16px;
}

.top-table h3 {
    font-size: 14px;
    line-height: 21px;
    text-align: left;
    color: hsla(0, 0%, 0%, 1);
    font-weight: 600;
    /* width: max-content; */
    white-space: normal;
}

.top-table h6 {
    font-size: 17px;
    color: #336b87;
}


.top-table table tr td {
    /* padding: 5px; */
    vertical-align: middle;
    /* width: 100%; */
    white-space: nowrap;
    border: unset;
}

.top-table table {
    width: 100%  !important;
    border-collapse: collapse;
    border: 0;
    border-spacing: 0;
}
table.bottom-table td h5 {
  /* white-space: normal; */
  font-size: 19px;
  line-height: 22px;
  /* width: min-content; */
}
.top-table table.body-top {
    background: hsla(240, 3%, 94%, 1);
      /* table-layout: fixed;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis; */
}

.top-table {
    width: 1165px !important;
}

.bg-black {
    background: hsla(0, 0%, 0%, 1);
    padding: 10px 0;
}

.bg-black h5 {
    font-size: 18px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0.02em;
    text-align: center;
    color: #fff;
}

.bg-black p {
    font-size: 13px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0.02em;
    text-align: center;
    color: hsla(0, 0%, 100%, 1);
    padding: 0 6px;
}

table.biling-details h2 {
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    text-align: left;
}

table.common-table {
    border: 1px solid hsla(0, 0%, 85%, 1);
    background: #fff;
}

.common-table {
  width: fit-content;
  margin: 0;
  padding: 0;
  width: 33.33%;
}


/* .common-table{
    width: 30%;
} */

table.biling-details tr {
    border-bottom: 1.5px dotted hsla(0, 0%, 0%, 0.41);
}

table.biling-details tr td,
.body-top tr td,
.bottom-table tr td {
    padding: 10px 10px;
}

table.biling-details td h5 {
    font-weight: 700;
    font-size: 16px;
}

.check-img img {
    width: 30px;
}

.bottom-table tbody tr:last-child td {
    padding-bottom: 40px;
    white-space: normal;
}

.biling-details tbody tr:last-child {
    border-bottom: 0;
}

.biling-details span {
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: hsla(227, 80%, 64%, 1);
}

.scnr-img img {
    width: 75px;
}

.slot {
    border: 1px solid hsla(0, 0%, 0%, 1);
    padding: 10px;
}

.slot h2 {
    font-size: 12px;
    font-weight: 600;
    line-height: 19px;
    text-align: left;
    white-space: normal;
}

.bg-white {
    background-color: #fff;
}

.slot.bg-white h1 {
    font-size: 20px;
}

td.cmn-width {
    vertical-align: top;
    width: 33.33%;
}

table.bottom-table {
    /* table-layout: fixed;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; */
}

tr.Signature h4 {
    font-size: 13px;
    line-height: 17px;
    white-space: normal;
}

.top-table table tr td {
    white-space: normal;
}

/* .top-table .table>thead {
  vertical-align: top;
  background-color: #dfe6ff;
}
table, th, td {
  border: 1px solid rgb(33 37 48 / 18%);
  border-collapse: collapse;
}
.table>thead {
  vertical-align: top;
  background-color: #dfe6ff;
}
table, th, td {
  border: 1px solid rgb(33 37 48 / 18%);
  border-collapse: collapse;
} */

fieldset.css-igs3ac {
  border: none;
  inset: initial;
}
.react-datepicker-wrapper {
  display: block !important;
}
.custom-form-group label {
  display: block ;
  text-align: left;
  margin: 0 0 6px 0;
}
.custom-form-group {
  position: relative;
}

.icon_header {
  vertical-align: middle;
  line-height: 1px;
  font-size: 26px;
}

.icon,
.icon_header {
  margin-right: 5px;
}

/* Header  */
.header {
  box-shadow: 0px 10px 30px 0px #00000014;
  grid-area: header;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px 0 30px;
  margin: 20px;
  background-color: white;
  border-radius: 15px;
}

.header-left h3 {
  font-family: Poppins;
  font-size: 22px;
  font-weight: 500;
  line-height: 54px;
  text-align: left;
}

.header-right {
  display: flex;
  align-items: center;
  gap: 30px;
}

.user-details img {
  width: 45px;
  margin-right: 10px;
}

.header-notification {
  width: 20px;
}

.booking-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.booking-header h4 {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 700;
  line-height: 33px;
  text-align: left;
  color: black;
}

.booking-header a {
  font-family: Poppins;
  font-size: 11px;
  font-style: italic;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  color: #F7931E;
}

.ldc-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 10px;
  min-height: 60px;
}

.lot-30car .ldc-header {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.lot-30car .ldc-body .lot-box {
  color: #100f92;
}

.policies-details ul {
  list-style-type: disc;
  padding-left: 20px;
}

.policies-details li {
  margin-bottom: 10px;
}

.note {
  color: red;
  margin-right: 5px;
}